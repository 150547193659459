import React from "react"
import Text from "../styles/typography"
import { css, useTheme } from "@emotion/react"
import _ from "lodash"
import { Link } from "gatsby"

const TagList = ({ tags }) => (
  <div
    css={css`
      text-align: right;
      margin-top: 1rem;
    `}
  >
    {tags &&
      tags.map((tag, i) => (
        <React.Fragment>
          {i !== 0 && ", "}
          <Text
            el={Link}
            to={`/tags/${_.kebabCase(tag)}`}
            css={css`
              font-weight: bold;
              text-decoration: none;
              position: relative;
              z-index: 1;
            `}
          >
            {tag}
          </Text>
        </React.Fragment>
      ))}
  </div>
)

export default TagList
